[class^="btn"] {

  &:not(.btn-link):not(.btn-icon){
    font-size:1rem;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-weight:700;
    transition:.3s all linear;
    @include media-breakpoint-down(md) {
      font-size:.75rem !important;
    }

  }


  &:hover, &:active, &:focus {
    text-decoration: none;
    outline:none;
    box-shadow:none;
  }
}

.btn-link {
  font-weight:600;
  font-size:.75rem;
  letter-spacing: .5px;
  text-transform: initial;
  text-decoration:underline;
  &:hover, &:focus, &:active {
    text-decoration:underline;
  }
}
.btn-next {
  padding: 1.5rem 1rem;
  min-width: 200px;
  border: none;
  font-size: 22px;
  background-color: $silver;
  color: $white;
  text-transform: uppercase;
  letter-spacing: 3px;

  &:not(:disabled) {
    background-color: $sunglow;

    &:hover {
      background-color: $white;
      color: $sunglow;
    }
  }
}

.btn-quiz-back {
  position: absolute;
  top: 1.5rem;
  right: 0;
  border: none;
  background: transparent;
  color: $sunglow;

  &:hover {
    color: $emperor;
  }
  @include media-breakpoint-down(md) {
    font-size:.75rem !important;
  }
}

.btn-show-modal {
  color: $white;
  display: flex;
  align-items: center;
  margin: 1.5rem auto;
  text-transform: uppercase;
  font-weight:700;
  letter-spacing: 3px;
  background: transparent;
  border:0;
  &:hover, &:focus, &:active {
    background: transparent !important;
    border:0 !important;
  }
  &.dark {
    color: $sunglow;

    &:hover {
      color: $emperor;
    }
  }

  &:hover {
    color: $white;

    .play-symbol {
      background-color: $white;
      background-image: url('../../assets/icons/icon-play-orange.png');
    }
  }

  .play-symbol {
    margin-left: 1rem;
    border-radius: 50%;
    background: $sunglow;
    background-image: url('../../assets/icons/icon-play-white.png');
    background-position: 55% center;
    background-repeat: no-repeat;
    background-size: 16px;
    height:45px;
    width: 45px;
    transition:.3s all linear;
    &.xl {
      background-size: 25px;
      height: 75px;
      width: 75px;
    }

    &:before {
      content: '';
    }
  }
}

.btn-icon {
  border-radius: 50%;
  background: $sunglow;
  border: none;
  width: 45px;
  height: 45px;
  position:relative;

  img {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -10px;
    margin-left: -10px;
  }
  &.btn-close-modal {
    height:34px;
    width:34px;
    img {
      width: 14px;
      height: 14px;
      margin-top: -7px;
      margin-left: -7px;
    }

  }
  &:active, &:focus, &:hover {
    background-color: $white !important;
    color: $sunglow !important;
    border: none;
  }
}

.btn-close-modal {
  position: absolute;
  right: 10px;
  top: 10px;
  transition:.3s all linear;
}

.btn-start-quiz-container {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);

  .btn-start-quiz {
    background-color: transparent;
    border: none;
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
  }
}

@include media-breakpoint-down(lg) {

  .btn-start-quiz-container {
    position: relative;
    bottom: 0rem;
    margin: 1rem 0;
    left: 50%;
    transform: translateX(-50%);

    .btn-start-quiz {
      background-color: transparent;
      border: none;
    }
  }

  .btn-quiz-back {
    top: 0.5rem;
  }

  .btn-show-modal {
  
    .play-symbol {
      background-size: 15px;
      height: 40px;
      width: 40px;
    }
  }

}
