@import url("//hello.myfonts.net/count/3c6926");

@font-face {
  font-family: 'AvenirNextLTPro';
  src: url('../../assets/fonts/AvenirNextLTPro-Regular/font.woff2') format('woff2'), url('../../assets/fonts/AvenirNextLTPro-Regular/font.woff') format('woff');
  font-weight:400;
}
@font-face {
  font-family: 'AvenirNextLTPro';
  src: url('../../assets/fonts/AvenirNextLTPro-Demi/font.woff2') format('woff2'), url('../../assets/fonts/AvenirNextLTPro-Demi/font.woff') format('woff');
  font-weight:600;
}

@font-face {
  font-family: 'AvenirNextLTPro';
  src: url('../../assets/fonts/AvenirNextLTPro-Bold/font.woff2') format('woff2'), url('../../assets/fonts/AvenirNextLTPro-Bold/font.woff') format('woff');
  font-weight:700;
}
$font-family-sans-serif: 'AvenirNextLTPro', 'Montserrat', Arial, sans-serif;

body {
  font-family: $font-family-sans-serif;
  color: $emperor;
}
input, textarea {
  color: $emperor;
}
.title-text {
  font-size: 4.5rem;
  font-weight:700;
  @include media-breakpoint-down(md) {
    font-size:2.5rem;
  }
}

.intro-text {
  font-weight: 600;
  font-size: 1.2rem;
  @include media-breakpoint-up(lg) {
      padding-left:100px;
      padding-right:100px;
  }
  @include media-breakpoint-down(md) {
    font-size:1rem;
  }
}
h1 {
  font-weight:700;
  font-size: 2.5rem;
  margin-bottom:1rem;
  @include media-breakpoint-down(md) {
    font-size:1.75rem;
    line-height:1.2em;
  }
}
h3 {
  font-size: 1.2rem;
  font-weight:600;
  @include media-breakpoint-down(md) {
    font-size:1rem;
  }
}
.text-bold {
  font-weight: 700;
}
.text-demibold {
  font-weight:600;
}

.text-dark {
  color: $emperor;
}

.text-light {
  color: $white;
}

.text-gold {
  color: $sunglow;
}

@include media-breakpoint-down(md) {
  .title-text {
    font-size: 3rem;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: $white;
}

::-webkit-scrollbar-thumb {
  background-color: $emperor;
  outline: 1px solid $emperor;
}

.explanation-text {
  font-size: 1.2rem;
  @include media-breakpoint-down(md) {
    font-size:1rem;
  }
}
