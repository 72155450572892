@-moz-keyframes bounce {
  0%, 20%, 50%, 70%, 100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-14px);
    transform: translateY(-14px);
  }
  60% {
    -moz-transform: translateY(-7px);
    transform: translateY(-7px);
  }
}
@-webkit-keyframes bounce {
  0%, 20%, 50%, 70%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-14px);
    transform: translateY(-14px);
  }
  60% {
    -webkit-transform: translateY(-7px);
    transform: translateY(-7px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 70%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-14px);
    -ms-transform: translateY(-14px);
    -webkit-transform: translateY(-14px);
    transform: translateY(-14px);
  }
  60% {
    -moz-transform: translateY(-7px);
    -ms-transform: translateY(-7px);
    -webkit-transform: translateY(-7px);
    transform: translateY(-7px);
  }
}
