.dropdown-container {

  select.occupation-container {
    border: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='72.23' height='37.17' viewBox='0 0 72.23 37.17'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %234f4f4f; %7D %3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Design'%3E%3Cpath class='cls-1' d='M36.11,37.17a4,4,0,0,1-2.69-1L1.31,7A4,4,0,0,1,6.69,1L36.11,27.77,65.54,1A4,4,0,1,1,70.92,7L38.8,36.14A4,4,0,0,1,36.11,37.17Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-position: 97% center;
    background-repeat:no-repeat;
    background-size:30px;
    background-color: transparent;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    padding: 5px;
    width: 100%;
    padding-right: 50px;
    font-weight:700;
    @include media-breakpoint-down(md) {
      font-size:.875rem;
      background-size:12px;
    }
  }

  select::-ms-expand {
    display: none;
  }

  select.occupation-container:focus + .line::before {
    width: 100%;
    background-color: $emperor;
    left: 0;
  }
}
