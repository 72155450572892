.moodboard {
  position: relative;
  padding: 0 0.975rem;

  .image-bg {
    height:50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &.main {
      height:0;
      padding-top: 57%;
    }
  }

  .color-row {
    height:0;
    padding-top:4%;
  }

  .words-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #00000060;
    display: flex;
    flex-direction: column;
    padding-left: 25%;

    .words-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .associated-words {
      font-weight:600;
      font-size: 100%;
      color: $white;
      svg {
        width:70%;
        text {
          font-size:100%;
          fill: $white;
        }
      }
    }
  }


}

.moodboard-modal {
  .modal-dialog {
    max-width: 1000px;
  }
  .modal-body {
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
  }
}
