.close-modal {
  z-index: 1;
}

.select-grid-image {
  .grid-image-container {
    position: relative;
    margin: 12px 8px;
    width: 200px;
    @include media-breakpoint-down(md) {
      width:100%;
    }
  }
  .grid-image-hover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.65);
  }
  .grid-image {
    height: 110px;
    width: 200px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    @include media-breakpoint-down(md) {
      width:100%;
      background-size:cover;
    }
  }
  .image-dropzone {
    font-size: 1rem;
    text-align: center;
    .upload-you-own {
      font-size: .75rem;
      font-weight: 600;
    }
    .choose-your-own {
      text-align: left;
      font-size: .875rem;
      .choose-file {
        color: $sunglow;
        text-decoration: underline;
      }
    }
    &.disabled {
      color: $silver;
      .choose-file {
        color: $silver;
      }
    }
  }

}

.select-grid-text {
  .grid-item {
    padding: 1rem 0;
    border: 1px solid transparent;
    background-color: transparent;
    color: $silver;

    &:hover {
      font-weight:600;
      color: $emperor;

      &:disabled {
        color: $silver;
        font-weight:500;
      }
    }

    &.is-selected {
      border: 1px solid $sunglow;
      background-color: $sunglow;
      color: $emperor;
      font-weight:600;
    }


  }
}

.select-grid-color {
  margin: 1rem 0;
  .grid-item {
    height: 95px;
    width: 158px;
    margin: 0 16px 32px;
    outline-offset: 5px;
    border: none;

    &.is-selected {
      outline: 3px solid $emperor;

      &:hover {
        transform: none;
      }
    }

    &:hover {
      transform: scale(1.1);

      &:disabled {
        transform: none;
      }
    }
  }
}

.modal-50w {
  max-width: 50vw;
}

@include media-breakpoint-down(xs) {

  .modal-50w {
    max-width: initial;
  }
  .select-grid-color {
    .grid-item {
      width: 100%;
    }
  }
}
