header {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  color: $white;
  padding-top: 30px;


  .header-logo-container {
    margin: 0;
  }

  .city-logo {
    max-width: 325px;
    margin: 0 1rem;

  }

  .progress-bar {
    height: 3px;
    line-height: 1;
    border-radius: 10px;
    position: relative;
    background-color: rgba($silver, .5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
    min-width: 500px;
    @include media-breakpoint-down(md) {
      height:2px;
    }
    .step {
      z-index: 0;
      position: absolute;
      top: -8px;
      transform: translateX(-88%);
      transition-property: all;
      transition-timing-function: ease;
      @include media-breakpoint-down(md) {
        top:-6px;
      }

      .label {
        font-weight:600;
        position: absolute;
        top: -20px;
        left: 50%;
        white-space: nowrap;
        transform: translateX(-50%);
        color: rgba($silver, .5);
        font-size:.75rem;

      }

      .indicator {
        color:$white;
        width: 20px;
        height: 20px;
        font-size: 12px;
        border: 3px solid rgba($silver, .5);
        background-color: #1b2733; //blend it into bg better
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        @include media-breakpoint-down(md) {
          width:14px;
          height:14px;
          border-width:2px;
        }
      }

      &.accomplished {

        .indicator{
          background-color: $sunglow;
          border: none;
        }
        
        .label {
          color: $sunglow;
        }
      }
    }

    .progression {
      position: absolute;
      transition: width 0.3s ease;
      left: 0;
      top: 0;
      bottom: 0;
      border-radius: 10px;
      background: $sunglow;
      z-index: -1;
    }
  }

  @include media-breakpoint-down(lg) {
    padding-top: 0rem;
    height:auto;
    .header-logo-container {
      margin: 0 auto;
    }

    .city-logo {
      max-width:200px;
      margin:.5rem auto;
    }

    .progress-bar {
      top: 10px;
      min-width: 90%;
      margin: auto;

      .step{

        .label {
          display: none;
        }
      }
    }
  }
}
