input, textarea, select {
  color: $emperor;
}
.line {
  position: relative;
  height: 2px;
  background-color: $silver;
  width: 100%;

  &:before {
    content: '';
    height: 2px;
    width: 0px;
    position: absolute;
    background-color: transparent;
    display: inline-block;
    transition: .3s all ease-in-out;
    top: 0;
    left: 50%;
  }

}
.input-container {

  .after {
    position: absolute;
    font-size: .75rem;
    bottom: 10px;
    right: 1.5rem;

    &:after {
      content: url('../../assets/icons/icon-arrow-return-grey.png');
      height: 20px;
      width: 20px;
      position: absolute;
    }
  }



  textarea {
    height: 1em;
    border: none;
    width: 100%;
    font-size:1.25rem;
    font-weight:600;
    background-color: transparent;
    position: relative;
    padding-right: 150px;

    &:focus {
      outline: none;
    }
  }

  textarea:focus + .line::before {
    width: 100%;
    background-color: $emperor;
    left: 0;
  }

  @include media-breakpoint-down(md) {
    textarea {
      padding-right: 0;
    }

    .after {
      display: none;
    }
  }
}
.char-count {
  font-size:.75rem;
  font-weight:600;
  text-align:left;
  position:absolute;
  top:-25px;
  right:0;
}
.prev-question-container {
  transform: scale(.8);

  .question-heading {
    .heading-text {
      color: $silver;
    }
  }

  .prev-answer {
    border-bottom: 2px solid $silver;
    text-align: left;
    color: $silver;
  }
}
