.select-inline {
  position:relative;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;

  &.has-selection, &:hover {

    .line::before {
      background-color: $emperor;
      width:100%;
      left:0;
    }
  }
  .line {
    position: absolute;
    bottom:0;
    left:0;
    right:0;
  }
  .btn-select-inline {
    margin: 0 16px;
    padding: .5rem 1rem;
    border: none;
    background-color: $alabaster;
    color: $silver;
    position: relative;
    display: flex;
    align-items: center;

    &.male:before {
      content: url('../../assets/icons/icon-male-light-grey.png');
      display: inline-block;
    }

    &.female:before {
      content: url('../../assets/icons/icon-female-light-grey.png');
      display: inline-block;
    }

    &:hover {
      color: $emperor;

      &.female:before {
        content: url('../../assets/icons/icon-female-grey.png')
      }

      &.male:before {
        content: url('../../assets/icons/icon-male-grey.png')
      }
    }

    &:hover + .line {
      background-color: $emperor;
    }

    &.is-selected {
      background-color: $sunglow;
      color: $emperor;

      &.female:before {
        content: url('../../assets/icons/icon-female-grey.png');
      }

      &.male:before {
        content: url('../../assets/icons/icon-male-grey.png');
      }

      &:focus,
      &:active {
        outline: $sunglow;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;

    .btn-select-inline {
      margin: 1rem 0;
    }

    .line {
      display: none;
    }
  }
}
