$white: #fff;
$sunglow: #FFC429;
$silver: #c8c8c8;
$emperor: #4f4f4f;
$alabaster: #f8f8f8;



$colors: (
  'white': $white,
  'silver': $silver,
  'alabaster': $alabaster,
  'sunglow': $sunglow,
  'emperor': $emperor,
);

@each $colorName, $hexCode in $colors {
  .bg-#{$colorName} {
    background-color: #{$hexCode};
    background-image: none !important;
    color: invert($hexCode);
  }
}
