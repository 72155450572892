html,
body {
  height: 100vh;
  overflow: hidden;
  margin: 0;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  transition: all 450ms ease-in-out;

  &.content {
    color: #fff;
    opacity: 1;
    align-items: center;
    justify-content: center;
  }

  &.quiz {
    background: $alabaster;
    top: 120px;
    transform: translateY(100%);
    @include media-breakpoint-down(md) {
      top:100px;
    }
  }
}

.open {
  .content {
    opacity: 0;
    transform: translateY(-100%);
  }

  .quiz {
    transform: translateY(0%);
  }
}

.background {
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@include media-breakpoint-down(lg) {
  .fullscreen {
    // justify-content: start !important;
    //overflow: auto;

    &.content {
      margin-top: 140px;
    }

    &.quiz {
      margin-top: 80px;
    }
  }
}

@include media-breakpoint-down(md) {
  .fullscreen {
    &.quiz {
      margin-top: 0px;
    }
    &.content {
      margin-top: 100px;
    }
  }
}
