.quiz-slide {
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  left: 15px;
}

.quiz-header {
  display: flex;
  align-items: center;
  background: #4f4f4f;
  font-weight:700;
  font-size:1.2rem;
  @include media-breakpoint-down(md) {
    display:none;
  }
}

.quiz-slides {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-basis: auto;

  min-height: 0;
  overflow: hidden;
}

.quiz-questions-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.quiz-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 100%;
  flex-basis:100%;

  min-height: 0;
  font-size: 1.5rem;
  //overflow: auto;
}

.quiz-content-question {
  text-align:center;
  min-width: 0;
  margin-top: 60px;
  overflow: auto;
  label {
    display:block;
  }
  @include media-breakpoint-down(md) {
    margin-top: 40px;
  }
}

.quiz-content-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 auto;
  flex-basis: auto;
  padding: 20px;
}

.question-count {
  font-size:1.2rem;
  font-weight:700;
  color: $silver;
  position: absolute;
  top: 1.5rem;
  left: 0;
  @include media-breakpoint-down(md) {
    font-size:.75rem;
  }
}

.question-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right:auto;
  width: 100%;
  text-align: center;

  @include media-breakpoint-down(md) {
    img {
      display:none;
    }
  }
  .heading-text {
    font-weight:700;
    font-size: 2.5rem;
    margin: 1rem;
    color: $emperor;
    max-width: 800px;
    @include media-breakpoint-down(md) {
      font-size: 1.5rem;
      line-height:1.2em;
    }
  }
  img + .heading-text {
    font-size:1.8rem;
    text-align:left;
    padding-left:1rem;
  }
}
.end-quiz-message {
  font-size: 1.5rem;
  margin-bottom:2rem;
  @include media-breakpoint-up(lg) {
    padding-left:200px;
    padding-right:200px;
  }
  @include media-breakpoint-down(md) {
    font-size:1rem;
  }
}
.explanation-modal {
  border-radius: 0 !important;

  .modal-content {
    border-radius: 0 !important;

    .modal-body {
      background-color: $emperor;
      color: $white;
     padding: 2.5rem 5rem;
      @include media-breakpoint-down(md) {
        padding: 4rem 20px;
      }
    }
  }
}
.modal-backdrop {
  background: #fff;
}
.modal-dialog {
  max-width: 550px;
  font-weight:600;
  font-size:1.2rem;
}
.modal-content {
  border:0;
}
.video-modal {
  padding-left: 0 !important;
  padding-right: 0 !important;

  .modal-dialog {
    max-width: 100% !important;
    margin: auto;
    
    .modal-body {
      display: flex;
      padding: 0;

      .video-modal-iframe {
        height: 100vh;
        width: 100%;
        border: none;
        background-color: $emperor;
      }

    }
  }
}

.quiz-thank-you {
  text-align: center;
  margin: 50px 0 20px;
  overflow: auto;

}

@include media-breakpoint-down(lg) {
  .quiz-content {
    justify-content: flex-start;
  }

  .question-count {
    top: .5rem;
  }

  .question-heading {
    flex-wrap: wrap;
    width: 100%;
  }

  .quiz-questions-container {
    margin: 50px 0 20px;
  }

  .explanation-modal {

    .modal-content {

      .modal-body {
        padding: 4rem 20px;
      }
    }
  }
}

@include media-breakpoint-down(xs) {

  .quiz-content-actions {
    padding: 0px;
  }
}
